import React from 'react';
import Layout from '../components/Layout';
import Hero from '../components/Hero-all';
import Presents from '../components/Presents';

const HbdPage = () => {
  const pictures = [
    'IMG_4994.jpeg',
    'IMG_4995.jpeg',
    'IMG_4996.jpeg',
    'IMG_4997.jpeg',
    'IMG_4998.jpeg',
    'IMG_0754.jpeg',
    'IMG_5007.jpeg',
    'IMG_4999.jpeg',
    'IMG_5012.jpeg',
    'IMG_4993.jpeg',
    'IMG_5001.jpeg',
    'IMG_5002.jpeg',
    'IMG_5004.jpeg',
    'IMG_4991.jpeg',
    'IMG_5011.jpeg',
    'IMG_4992.jpeg',
    'IMG_5005.jpeg',
    'IMG_5006.jpeg',
    'IMG_5008.jpeg',
    'IMG_5009.jpeg',
  ];

  const pictures1 = [
    'IMG_4961.jpeg',
    'IMG_4962.jpeg',
    'IMG_4963.jpeg',
    'IMG_4964.jpeg',
    'IMG_5013.jpeg',
    'IMG_4965.jpeg',
    'IMG_4967.jpeg',
    'IMG_4968.jpeg',
    'IMG_4969.jpeg',
    'IMG_4970.jpeg',
    'IMG_4971.jpeg',
    'IMG_4972.jpeg',
    'IMG_4973.jpeg',
    'IMG_4974.jpeg',
    'IMG_4371.jpeg',
    'IMG_4975.jpeg',
    'IMG_4976.jpeg',
    'IMG_4977.jpeg',
    'IMG_4978.jpeg',
    'IMG_4979.jpeg',
    'IMG_4980.jpeg',
    'IMG_4982.jpeg',
    'IMG_4983.jpeg',
    'IMG_4984.jpeg',
    'IMG_4985.jpeg',
    'IMG_4986.jpeg',
    'IMG_4987.jpeg',
    'IMG_7531.jpeg',
    'IMG_7532.jpeg',
    'IMG_7539.jpeg',
    'IMG_4989.jpeg',
    'IMG_4990.jpeg',
  ];

  const pictures2 = [
    'IMG_6979.jpeg',
    'IMG_4988.jpeg',
    'IMG_E1026.jpeg',
    'IMG_0317.jpeg',
    'IMG_0354.jpeg',
    '39019_412496500058_586092_n.jpeg',
    'IMG_0828.jpeg',
    'IMG_1022.jpeg',
    'IMG_1032.jpeg',
    'IMG_1440.jpeg',
    '39065_412499235058_7980034_n.jpeg',
    'IMG_1457.jpeg',
    'IMG_1462.jpeg',
    'IMG_2962.jpeg',
    'IMG_2468.jpeg',
    'IMG_5254.jpeg',
    'IMG_5311.jpeg',
    'IMG_5324.jpeg',
    'IMG_7324.jpeg',
    'IMG_7607.jpeg',
    'IMG_7611.jpeg',
    'IMG_E3875.jpeg',
    'IMG_3455.jpeg',
    'IMG_0057.jpeg',
  ];

  const album = pictures.map((pic) => (
    <div className=" w-1/2 md:w-1/4">
      <img alt={pic} src={`/lauren/${pic}`} />
    </div>
  ));

  const album1 = pictures1.map((pic) => (
    <div className=" w-1/2 md:w-1/4">
      <img alt={pic} src={`/lauren/${pic}`} />
    </div>
  ));

  const album2 = pictures2.map((pic) => (
    <div className=" w-1/2 md:w-1/4">
      <img alt={pic} src={`/lauren/${pic}`} />
    </div>
  ));
  return (
    <Layout>
      <section>
        <div>
          <div>
            <Hero image="" title="Happy 30th Birthday Lauren!" />
          </div>

          <div className="max-w-xl m-auto mb-10">
            <pre>
              Hi Lauren,
              <br /> <br />
              I really struggled with what to do for your birthday to show you
              how amazing you are, but what I hated most was I couldn't be with
              you on your special day. So I harnessed my inner strengths and
              deep within myself and thought to make you a virtual birthday
              page. I hope you enjoy some pretty rad photographs and also gifts.
              Please scroll down to see more!
              <br /> <br />
              Love you boo thang!
            </pre>
          </div>

          <div>
            <div className="flex flex-wrap">{album}</div>
          </div>
          <div className="max-w-2xl m-auto my-10">
            <h3 className="mb-2">30 things you have taught me...</h3>
            <i>(in no particular order)</i>
            <ol className="list-decimal mt-2 ml-8">
              <li className="list-item list-outside">
                Anything and everything about plants.
              </li>
              <li className="list-item list-outside">
                Art is cool. And creatives are some of the best people.
              </li>
              <li className="list-item list-outside">
                Concerts. From my first one to first festival. You know how to
                rock.
              </li>
              <li className="list-item list-outside">Music. Is this Guster?</li>
              <li className="list-item list-outside">
                How to wear a messy bun.
              </li>
              <li className="list-item list-outside">
                How to win an argument against Dad, and do it well.
              </li>
              <li className="list-item list-outside">
                Santa, the tooth fairy, and the Easter bunny are not real.
              </li>
              <li className="list-item list-outside">
                Yoga is good for the mind, body, and soul.
              </li>
              <li className="list-item list-outside">
                When you get blamed for something you didn't do, (like flooding
                the kitchen) take a lap around the block.
              </li>
              <li className="list-item list-outside">
                Egyptian Rat Screw is not for the weak.
              </li>
              <li className="list-item list-outside">
                How to make the game spoons more fun.
              </li>
              <li className="list-item list-outside">
                Rock climbing is fun and heights can be conqured. (or at least
                be less scary)
              </li>
              <li className="list-item list-outside">
                You can learn to love nature. Even the bugs.
              </li>
              <li className="list-item list-outside">
                Nut butters can change lives.
              </li>
              <li className="list-item list-outside">How to play beer pong.</li>
              <li className="list-item list-outside">Support local.</li>
              <li className="list-item list-outside">
                Shrimp butter tops all other butters.
              </li>
              <li className="list-item list-outside">
                What your body should consume and most often it's not meat,
                milk, or sugar.
              </li>
              <li className="list-item list-outside">
                Not everything's a competition.
              </li>
              <li className="list-item list-outside">
                How to confront others and converse in a way the other person is
                heard.
              </li>
              <li className="list-item list-outside">
                How to be a{' '}
                <b>
                  strong, confident, focused, empowered, creative, inspiring
                </b>{' '}
                career woman.
              </li>
              <li className="list-item list-outside">
                Never put up with crap from a boy. If you aren't happy, loved,
                respected: Drop his ass because I don't deserve that.
              </li>
              <li className="list-item list-outside">
                Family is one of the most important things. Family with always
                be a constant.
              </li>
              <li className="list-item list-outside">
                Sometimes people just don't share your point of view. And that's
                no reason to treat them differently.
              </li>
              <li className="list-item list-outside">
                How to love myself and be patient with myself.
              </li>
              <li className="list-item list-outside">
                I don't need to "work on it." I can accept myself for who I am
                and people will as well.
              </li>
              <li className="list-item list-outside">
                The feeling of being protected out of sheer love. You will
                forever be my security guard. (see photos for proof)
              </li>
              <li className="list-item list-outside">
                People can have the strongest habits, opinions and beliefs, but
                they are all able to be changed.
              </li>
              <li className="list-item list-outside">
                A sister will always be there for you, even if you give them
                many reasons to not be.
              </li>
              <li className="list-item list-outside">
                How to be an incredible sister. &lt;33
              </li>
            </ol>
            <hr />
          </div>

          <div>
            <div className="flex flex-wrap">{album1}</div>
          </div>

          <div className="max-w-2xl m-auto my-10">
            <h4>
              Sometimes people buy things when they shouldn't and make it hard
              for people to buy things for them but I respect that game of
              buying what you want so...
            </h4>
            <img
              className="w-1/2 m-auto"
              alt="Apple pen"
              src="/lauren/apple.png"
            />
            <h4 className="text-right">...I am neutral on the matter.</h4>
          </div>

          <div>
            <div className="flex flex-wrap">{album2}</div>
          </div>

          <div className="max-w-4xl m-auto my-10">
            <h2>Let's get to some gift giving... shall we?!</h2>
            <div className="flex">
              <img
                className="w-full"
                alt="Kid bday"
                src="/lauren/beyonce.gif"
              />
              <img
                className="w-full"
                alt="Oprah bday"
                src="/lauren/oprah.gif"
              />
              <img className="w-full" alt="Kid present" src="/lauren/kid.gif" />
            </div>

            <Presents />

            <h2 className="mt-10">But sometimes you have to...</h2>
            <div className="max-w-xl m-auto my-10 text-center">
              <img
                className="inline-block mb-4"
                src="/lauren/treat.png"
                alt="Treat yo self"
              />

              <div>
                <pre>
                  Whenever this pandemic is under control, and Planet Granite
                  opens back up, I want you to keep kicking ass on those slabs
                  and enjoy
                  <br />
                  <br />
                  WOMEN’S BALANCE AND TECHNIQUE
                  <br /> & <br />
                  CRACK CLIMBING CLINICS
                  <br />
                  <br />
                  After I know you've read this, you should get an email with
                  the funds on your membership. Sha brah! Send it :)
                </pre>
                <br />
                <br /> <br />
                <br /> <br />
                <br /> <br />
                <br />
                <h4>&lt;3 XOXO Rach </h4>
                <p>
                  <b>P.S. </b>
                  Happy Birthday! I love you!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default HbdPage;
