import React from 'react';

export default class Presents extends React.Component {
  render() {
    return (
      <div className="gifts">
        <div className="max-w-4xl m-auto my-10">
          <h3>In your name...</h3>
          <pre>
            I have chosen three charities I think you would enjoy and support. I
            have given them each $30 in your name. Go ahead and read more about
            them.
          </pre>
        </div>

        <hr />

        <div id="presentOne" className="flex justify-between items-center m-4">
          <div className="w-2/3 mr-6 is-8">
            <h3>MELANIN BASE CAMP: Titan Project</h3>
            <a
              className="block my-2"
              target="_blank"
              rel="noreferrer"
              href="https://www.melaninbasecamp.com/donate"
            >
              https://www.melaninbasecamp.com/donate
            </a>

            <b className="block my-2">
              Why I chose this for you: I came across{' '}
              <a className="linkin" href="https://www.diversifyoutdoors.com/">
                Difersify Outdoors
              </a>{' '}
              and I thought this was an awesome project to get behind. The
              larger umbrella is Melanin Base Camp, which also has a ton of
              links to great organizations on their website.
            </b>

            <p>
              About the Project: "Melanin Base Camp is partnering with Mountain
              Equipment Co-op & Marmot to produce a short film about Sabrina
              Chapman. She’s a black Canadian climbing prodigy on a mission to
              complete her first 5.14a!"
            </p>
          </div>
          <img className="w-1/3" src="/lauren/mbc.jpg" alt="MBC" />
        </div>
        <hr />

        <div id="presentTwo" className="flex justify-between items-center m-4">
          <div className="w-2/3 mr-6  is-8">
            <h3>Satya Yoga Cooperative</h3>
            <a
              className="block my-2"
              target="_blank"
              rel="noreferrer"
              href="https://satyayogacooperative.com/"
            >
              https://satyayogacooperative.com/
            </a>
            <b className="block my-2">
              Why I chose this for you: I came across SYC in an{' '}
              <a
                className="linkin"
                href="https://www.outsideonline.com/2415446/satya-yoga-bipoc-teacher-training-denver"
              >
                Outside Magazine
              </a>{' '}
              email. They are the nation's first yoga teacher training program
              for people of color, and are trying to change who's leading yoga
              classes.
            </b>

            <p>
              About the COOP: Their mission is to be a network of conscious yoga
              teachers of color, to model and teach a liberation oriented yoga
              that acknowledges systems that fracture wholeness, to integrate
              the innovation and wisdom of People of Color, to offer
              economically accessible yoga classes, events and educational
              materials, and to model a successful POC owned people-focused
              enterprise.
            </p>
          </div>
          <div className="w-1/3">
            <img src="/lauren/yoga.png" alt="Yoga" />
          </div>
        </div>
        <hr />
        <div
          id="presentThree"
          className="flex justify-between items-center m-4"
        >
          <div className="w-2/3 mr-6 is-8">
            <h3>Don't Shoot PDX</h3>
            <a
              className="block my-2"
              target="_blank"
              rel="noreferrer"
              href="https://www.dontshootpdx.org/"
            >
              https://www.dontshootpdx.org/
            </a>
            <b className="block my-2">
              Why I chose this for you: I saw it on your Instagram, and I think
              we talked about it. I don't think your realize how much your
              involvement in this movement has led me to educate myself, and
              take charge of my own journey of learning, taking action, and
              listening.
            </b>
            <p>
              You know this but... Don’t Shoot Portland is Black-led and
              community driven. Founded in 2014 by Teressa Raiford, we are a
              direct community action plan that advocates for accountability to
              create social change.
            </p>
          </div>
          <div className="w-1/3">
            <img src="/lauren/pdx.png" alt="PDX" />
          </div>
        </div>
        <hr />
      </div>
    );
  }
}
